import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import costumService from './../../assets/DontWhore/66.png'
import tm from './../../assets/DontWhore/logo1.png'
import ta from './../../assets/DontWhore/logo2.png'
import './DontWhore.css'
const DontWhore = () => {
    return (
        <div style={{ padding: '0px 0' }}  >
            <Box sx={{ py: 2 }} >
                <div className="bg">
                    <div className="overlayD"></div>
                    <Container>
                        <Box sx={{
                            display: 'flex', flexDirection: { md: 'row', xs: 'column' },
                            alignItems: 'center', justifyContent: 'space-between', width: '100%', position: 'relative', zIndex: 9
                        }} >
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, color: '#FFF', width:{md:'50%', xs:'100%'} }} >
                                <Typography variant='h5' >لا تشيل هم النظافه والصيانه مع عابر أبحر</Typography>
                                <Typography className='cercleD' > مؤسسه عابر أبحر لتركيب طارد الحمام بجدة  نقدم خدمات ممتازة لمكافحة الحمام والطيور والغربان بكفاءة واتقان، مع التزامنا الدقيق بالمواعيد والسرعة في التنفيذ. سواء كنت بحاجة إلى مؤسسة مكافحة الحمام بجدة ، أو تركيب طارد الحمام، أو تركيب مانع حمام،  او تركيب شبك الحمام بجدة فإننا هنا لنقدم لك أفضل الخدمات.</Typography>
                            </Box>
                            <Box>
                                <CardMedia
                                    component={'img'}
                                    src={costumService}
                                    sx={{
                                        width: '100%',
                                        height: '350px',
                                        objectFit: { md: 'fill', xs: 'contain' },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Container>
                </div>
            </Box>
        </div>
    )
}
export default DontWhore
