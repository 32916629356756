import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, .05)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#000' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .05)',
}));

export default function Accordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box style={{ width: { md: '49%', xs: '100%' } }} >
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" style={{ backgroundColor: "rgp(255,255,255, 0.5)" }} >
          <Typography style={{ color: "#000", fontWeight: "bold" }} >ما هي خدمات النظافة التي تقدمها المؤسسة؟</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            نحن نقدم مجموعة واسعة من خدمات النظافة بما في ذلك تنظيف المنازل والشقق ومكاتب الأعمال والفنادق والمدارس والمستشفيات وغيرها. نقوم أيضًا بخدمات التنظيف العميق وتنظيف السجاد والأثاث والنوافذ وتلميع الأرضيات وغيرها.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" style={{ backgroundColor: "rgp(255,255,255, 0.5)" }}>
          <Typography style={{ color: "#000", fontWeight: "bold" }}>هل تستخدم المؤسسة مواد تنظيف آمنة للبيئة؟</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            نعم، نحن نولي اهتمامًا كبيرًا بالبيئة ونستخدم مواد تنظيف آمنة وصديقة للبيئة في كل خدمة نقدمها. نحرص على استخدام المنظفات الخالية من الكيماويات الضارة ونهتم بالحفاظ على صحة العملاء والبيئة.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" style={{ backgroundColor: "rgp(255,255,255, 0.5)" }}>
          <Typography style={{ color: "#000", fontWeight: "bold" }}>هل أنا بحاجة لتوفير أدوات التنظيف والمعدات؟</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            لا تحتاج إلى توفير أدوات التنظيف والمعدات. نحن نأتي مجهزين بكل ما هو ضروري لتنفيذ الخدمة بشكل كامل وفقًا لاحتياجاتك.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}