import { Box, Button, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './cardservices.css'
import { useNavigate } from 'react-router-dom'
const Card = ({ path, name, route, desc }) => {
    const navigate = useNavigate()
    return (
        // <div>
        <Box className='box' position={'relative'}
            sx={{ boxShadow: "0px 3px 6px rgba(0, 0, 0,5%)", borderRadius: '12px', zIndex: 999, height: '100%' }} >
            <CardMedia
                component={'img'}
                style={img}
                src={path}
                sx={{
                    position: 'relative',
                    zIndex: 99,
                    transition: '0.5s',
                    '&:hover': {
                        transform: 'scale(1.05)'
                    }
                }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1, padding: 2 }} >
                <Typography variant='h5' sx={{ color: '#3B70A3' }}>{name}</Typography>
            <Button onClick={() => navigate(route)} sx={{
                color: '#3B70A3', backgroundColor: '#fff',
                textAlign: 'center',
                display: 'flex',
                margin: '5px auto',
                '&:hover': {
                    backgroundColor: '#fff',
                    color: '#3B70A3'
                }
            }} >المزيد</Button>
                {/* <Box className='show'>
                    <Typography variant='h5' sx={{ color: '#fff', textAlign: 'center' }}>{name}</Typography>
                    <Button onClick={() => navigate(route)} sx={{
                        color: '#3B70A3', backgroundColor: '#fff',
                        textAlign: 'center',
                        display: 'flex',
                        margin: '10px auto',
                        '&:hover': {
                            backgroundColor: '#fff',
                            color: '#3B70A3'
                        }
                    }} >المزيد</Button>
                </Box> */}
            </Box>
        </Box>
        // {/* </div> */ }
    )
}
const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    objectFit: 'fill',
    margin: 'auto'
}
export default Card
