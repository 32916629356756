import { Box, Typography } from '@mui/material'
import React from 'react'

const CardContact = ({ children, name, desc }) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            gap: '10px',
            padding: '8px',
            boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
            backgroundColor: '#FFF',
            flexDirection: 'row',
            position: 'relative',
            width: '100%'
        }} >
            {children}
            <Box textAlign={'center'} my={'10px'}>
                <Typography sx={{ fontWeight: '400', color: '#239698', fontSize: { md: '24px', xs: '20px' } }} >{name}</Typography>
                <Typography sx={{ fontWeight: '400', color: '#7F8284', fontSize: { md: '24px', xs: '20px' } }} >{desc}</Typography>
            </Box>
        </Box>
    )
}

export default CardContact
