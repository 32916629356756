import { Box, Button, Container, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
// import CardLogo from './CardLogo'
import PathPages from './PathPages'
import ContactData from './ContactData'
import DownFooter from './DownFooter'
import Information from './Information'
import { Link } from 'react-router-dom'
import bg from './../../assets/footer/19362690.png'
import { useTheme } from '@emotion/react'
const Footer = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
                <Box sx={{ background: '#164a79',display: 'flex', justifyContent: 'space-around', alignItems: 'center', gap: '8px', height:'100px' }} >
                        <Typography sx={{ fontWeight: '400', color: '#FFF', fontSize: '18px' }}  >تواصل معنا لطلب المزيد</Typography>
                        <Link
                            to="/contact-us"
                            style={{
                                color: "#FFF",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                        >
                            <Button variant="contained"
                                type="button"
                                sx={{
                                    backgroundColor: '#FFF', ':hover': {
                                        backgroundColor: '#FFF',
                                        color: '#3B70A3',
                                    },
                                    color: "#3B70A3",
                                    fontWeight: 'bold'
                                }}>تواصل معنا</Button>
                        </Link>
                    </Box>
            <div>
                <Box sx={{
                    background: `url(${bg})`, position: 'relative', backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }} >
                    <div className="overlayF"></div>
                    <Container>
                        <Grid container spacing={2} sx={{ alignItems: 'flex-start', justifyContent: 'center', pt: 1, position: 'relative' }} >
                            <Grid item md={6} xs={6}>
                                <PathPages />
                            </Grid>
                            {/* {isMobile || <Grid item md={1} xs={12}></Grid>} */}
                            <Grid item md={6} xs={6}>
                                <ContactData />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <DownFooter />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </div>
        </>
    )
}
export default Footer
