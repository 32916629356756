
import one from './../assets/services/3h.jpg'
import one1 from './../assets/services/3h.jpg'
import one2 from './../assets/services/3h.jpg'
import tow from './../assets/services/5mk.jpg'
import tow1 from './../assets/services/5mk.jpg'
import tow2 from './../assets/services/5mk.jpg'
import three from './../assets/services/2f.jpg'
import three1 from './../assets/services/2f.jpg'
import three2 from './../assets/services/2f.jpg'
import four from './../assets/services/4hm.jpg'
import four1 from './../assets/services/4hm.jpg'
import four2 from './../assets/services/4hm.jpg'
import six from './../assets/services/1s.jpg'
import six1 from './../assets/services/1s.jpg'
import six2 from './../assets/services/1s.jpg'
export const products = [
        {
                id: 1,
                imageUrl: [
                        one,
                        one1,
                        one2,
                ],
                name: `مكافحة حشرات`,
                supName: `مكافحة حشرات `,
                desc: `الحشرات من أكثر الأشياء المزعجة بشكل غير معتاد و غير مرغوب فى تواجها من الأساس وذلك لأنها سبب إنتشار الأمراض و نقل العدوى بين أفراد الأسرة ناهيك عن الشعور السئ التى تتركة عند الجميع ، بالطبع لا أحد يرغب في ذلك يحدث ،لهذا يجب الإهتمام برش المبيدات بشكل منتظم و مستمر فى مواعيد محددة لقتل الحشرات و منعها من المنزل بشكل نهائي .`,
                supDesc: `نحن نستخدم أفضل المبيدات الحشرية الأمنة عند مكافحة بق الفراش والتخلص من كافة الحشرات و نقوم بالمتابعة الدورية كل 3شهور لضمان عدم عوداتها مرة أخرى ، لا تقلق لأننا نستخدم مواد أمنة على صحة الأطفال وكبار السن ودع الحشرات بشكل فعال معنا .`,
        },
        {
                id: 2,
                imageUrl: [
                        tow,
                        tow1,
                        tow2,
                ],
                name: `غسيل مكيفات `,
                supName: ` غسيل مكيفات  `,
                desc: `يتراكم الغبار على فلاتر ومرشحات المكيف مما يؤدي إلى ضعف تدفق الهواء وتقليل تأثير التبريد وتلوث الغرفة.`,
                supDesc: `تحتاج مكيفات الهواء باختلاف انواعها الى عملية تنظيف بصفة مستمرة ،لإزالة الأتربة والغبار . توفر مؤسسه عابر أبحر عمالة متخصصة فى غسيل وتنظيف كل أنواع المكيفات وتعبئة الفريون. تهتم المؤسسة بغسيل وتنظيف المكيفات الإسبلت، بإعتبار هذه المكيفات أكثر إنتشار فى المنازل ، وتقوم بتوفير طاقم فني خاص بالمؤسسة، ، وهناك طاقم عمال خاص بغسيل وتنظيف مكيفات الشببابيك التي تعد أكثر شيوعا فى كل العمائر السكنية، اما بالنسبة للكاسيت والباكيدج والتكيفات الخفيفة ، المنتشرة فى كل الفنادق الكبيرة وأيضا القصور، فيوجد طاقم فنى خاص بهذه المؤسسه.`,
        },
        {
                id: 3,
                imageUrl: [
                        three,
                        three1,
                        three2,
                ],
                name: `تعبئه فريون `,
                supName: `تعبئه فريون  `,
                desc: `يتراكم الغبار على فلاتر ومرشحات المكيف مما يؤدي إلى ضعف تدفق الهواء وتقليل تأثير التبريد وتلوث الغرفة.`,
                supDesc: `تحتاج مكيفات الهواء باختلاف انواعها الى عملية تنظيف بصفة مستمرة ،لإزالة الأتربة والغبار . توفر مؤسسه عابر أبحر عمالة متخصصة فى غسيل وتنظيف كل أنواع المكيفات وتعبئة الفريون. تهتم المؤسسة بغسيل وتنظيف المكيفات الإسبلت، بإعتبار هذه المكيفات أكثر إنتشار فى المنازل ، وتقوم بتوفير طاقم فني خاص بالمؤسسة، ، وهناك طاقم عمال خاص بغسيل وتنظيف مكيفات الشببابيك التي تعد أكثر شيوعا فى كل العمائر السكنية، اما بالنسبة للكاسيت والباكيدج والتكيفات الخفيفة ، المنتشرة فى كل الفنادق الكبيرة وأيضا القصور، فيوجد طاقم فنى خاص بهذه المؤسسه.`,
        },
        {
                id: 4,
                imageUrl: [
                        four,
                        four1,
                        four2,
                ],
                name: `طارد حمام `,
                supName: `طارد حمام  `,
                desc: `مؤسسه عابر أبحر لتركيب طارد الحمام بجدة `,
                supDesc: `نقدم خدمات ممتازة لمكافحة الحمام والطيور والغربان بكفاءة واتقان، مع التزامنا الدقيق بالمواعيد والسرعة في التنفيذ. سواء كنت بحاجة إلى مؤسسة مكافحة الحمام بجدة ، أو تركيب طارد الحمام، أو تركيب مانع حمام،  او تركيب شبك الحمام بجدة فإننا هنا لنقدم لك أفضل الخدمات`,
        },
        {
                id: 6,
                imageUrl: [
                        six,
                        six1,
                        six2,
                ],
                name: `تنظيف السجاد`,
                supName: `تنظيف السجاد`,
                desc: ` ريحنا ست البيت من غسيل السجاد والحرامات `,
                supDesc: `
غسيل كافة الانواع والاحجام باحدث الماكينات العالمية
`,
        },
];