import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box } from '@mui/material'
import Card from '../components/glopal/Card'
import bg from './../assets/DontWhore/66.png'
import HowToWork from '../components/HowToWork/HowToWork'
import Sfaq from '../components/Sfaq/Sfaq'
import OurPartners from '../components/Partners/OurPartners'
// import CardPart from '../components/Partners/CardPart'
// import part1 from './../assets/Ellipse 13.png'
// import part2 from './../assets/testimonial-2-100x100.png'
const About = () => {
    return (
        <div>
            <Header2 title={'من نحن'} link1={'الصفحة الرئيسية'} link2={'من نحن'} />
            <Box sx={{ padding: '25px 0' }}>
                <Card
                    path={bg}
                    name={'مؤسسة عابر أبحر'}
                    supName={'لخدمات التنظيف المتكامل'}
                    desc={' ريحنا ست البيت من غسيل السجاد والحرامات غسيل كافة الانواع والاحجام باحدث الماكينات العالمية'}
                    supDesc={` مكافحة حشرات في جدة رش مبيدات مكافحة الصراصير والنمل والفئران ومكافحة العقرب والبق والثعابين ومكافحة الصراصير تعتمد المؤسسه علي متخصصين و فنيين علي دراية كاملة بخصائص المبيدات الحشرية و أضرارها و كيفية استخدامها و لديهم خبرة في استهداف الأماكن التي تعيش فيها الحشرات للقضاء عليها تماما والتى تمنع ظهورها في المكان مرة اخرى و تعتمد المؤسسة أيضا علي اجهزة حديثة جدا لتوصيل المبيدات الي أماكن تواجد الحشرات مهما كان مكانها ضيق أو بعيد فالأجهزة تعمل بشحنات كهروستاتيكية و التي تقوم بدورها بتفتيت المبيد الي جزيئات صغيرة جدا فيستطيع ان يصل الي أبعد و أضيق الأماكن و من ثم القضاء علي الحشرات  تمامآ .
`}
                />
                <Sfaq />
                <HowToWork />
                <OurPartners />
            </Box>
        </div>
    )
}

export default About
