import React from 'react'
import { Box, CardMedia, Typography, useMediaQuery } from '@mui/material';
import Carousel from "react-material-ui-carousel";
import header2 from './../../assets/header/WhatsApp Image 2024-03-25 at 22.23.03_3daadc63.jpg'
import header3 from './../../assets/header/WhatsApp Video 2024-03-25 at 22.23.03_dc433596.mp4'
import { useTheme } from '@emotion/react';
const Header = () => {
     const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box position={'relative'} >
                <Typography variant={isMobile?'h5':'h4'} textAlign={'center'} my={2} >مؤسسة تنظيف المكيفات بجدة</Typography>
                <Carousel
                navButtonsAlwaysInvisible={true}
                autoPlay={false}
                    animation='slide'
                    IndicatorIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="10" viewBox="0 0 32 14">
                            <rect id="Rectangle_266" data-name="Rectangle 266" width="30" height="10" rx="2" />
                        </svg>
                    }
                    indicatorIconButtonProps={{
                        style: {
                            padding: '0',
                            fill: '#fff',
                            '&:hover': {
                                opacity: "0.6 !important"
                            },
                        }
                    }}

                    activeIndicatorIconButtonProps={{
                        style: {
                            fill: '#10446F',
                        }
                    }}
                    indicatorContainerProps={{
                        style: {
                            marginTop: '10px',
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: '3%',
                            zIndex: 9
                        }
                    }}
                    sx={{
                        width: "100%",
                        height: { md: "600px", xs: "300px" },
                        margin: "auto"
                    }}
                >
                    <CardMedia
                        sx={{ height: { md: "600px", xs: "300px" } }}
                        component="img"
                        alt="green iguana"
                        src={header2}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                        }}
                    />
                    <CardMedia
                        sx={{ height: { md: "600px", xs: "300px" } }}
                        component="video"
                        // autoPlay={true}
                        controls={true}
                        alt="green iguana"
                        src={header3}
                        style={{
                            width: "100%",
                            objectFit: "fill",
                            zIndex:999
                        }}
                    />
                </Carousel>
            </Box>
        </>
    )
}

export default Header
