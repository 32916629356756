import React from 'react'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import './OurPartner.css'
import partner from './../../assets/partners/Group 1051.png'
const OurPartners = () => {
    return (
        <div>
            <Box style={{ padding: '0px 0' }}>
                <div className="bg">
                    <div className="overlayP"></div>
                    <Container>
                        <Box sx={{ mt: { md: 6, xs: 2 }, position: 'relative', zIndex: 9 }} >
                            <Grid container spacing={2} alignItems={'center'}>
                                <Grid item md={6} xs={12} >
                                    <CardMedia
                                        component={'img'}
                                        src={partner}
                                        sx={{
                                            width: '100%',
                                            height: '350px',
                                            objectFit: 'contain'
                                        }}
                                    />
                                    {/* <QuiltedImageList /> */}
                                </Grid>
                                <Grid item md={6} xs={12} >
                                    <Box sx={{ color: '#FFF', display: 'flex', flexDirection: 'column', gap: 2 }} >
                                        <Typography variant='h4'>
                                            قالو عنا
                                        </Typography>
                                        <Typography>
                                            عانيت كثيراً فى البحث عن شركات النظافة التى تقدم لي خدمة بأعلى مستوى وأحدث
                                            المعدات و فريق متخصص ذو خبرة حتى وجدت مؤسسة عابر أبحر التى تقدم خدمات
                                            بشكل مميز.
                                        </Typography>
                                        <Typography variant='h4'>
                                            محمد أحمد
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </Box>
        </div>
    )
}

export default OurPartners
