import { Box, CardMedia, Container, Typography } from '@mui/material'
import React from 'react'
import aboutHome from './../../assets/AboutUs/cleaning-service.png'

const AboutUs = () => {
    return (
        <div style={{ padding: '0px 0 0 0' }} >
            <Container>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    justifyContent: 'space-between',
                    flexDirection: { md: 'row', xs: 'column' }
                }} >
                    <Box sx={{
                        width: { md: '50%', xs: '100%' },
                        padding: { md: 2, xs: 1 },
                        height: { md: '30vh', xs: '100%' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        justifyContent: 'center'
                    }} >
                        <Typography variant='h5' sx={{ color: '#3B70A3' }} >
                            مؤسسة عابر أبحر خبراء التنظيف المتكامل 
                        </Typography>
                        <Typography sx={{ color: '#969696' }} >
                            عابر أبحر مؤسسة تنظيف 
                        </Typography>
                        <Typography>
                            يتراكم الغبار على فلاتر ومرشحات المكيف مما يؤدي إلى ضعف تدفق الهواء وتقليل تأثير التبريد وتلوث الغرفة.تحتاج مكيفات الهواء باختلاف انواعها الى عملية تنظيف بصفة مستمرة ،لإزالة الأتربة والغبار . توفر مؤسسه عابر أبحر عمالة متخصصة فى غسيل وتنظيف كل أنواع المكيفات وتعبئة الفريون. تهتم المؤسسة بغسيل وتنظيف المكيفات الإسبلت، بإعتبار هذه المكيفات أكثر إنتشار فى المنازل ، وتقوم بتوفير طاقم فني خاص بالمؤسسة، ، وهناك طاقم عمال خاص بغسيل وتنظيف مكيفات الشببابيك التي تعد أكثر شيوعا فى كل العمائر السكنية، اما بالنسبة للكاسيت والباكيدج والتكيفات الخفيفة ، المنتشرة فى كل الفنادق الكبيرة وأيضا القصور، فيوجد طاقم فنى خاص بهذه المؤسسه.

                        </Typography>
                    </Box>
                    <Box >
                        <CardMedia
                            component={'img'}
                            style={img}
                            src={aboutHome}
                            sx={{ height: '450px', objectFit: { md: 'fill', xs: 'contain' }, }}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',

    borderRadius: '12px',
    backgroundSize: 'cover',
    // boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',

    margin: 'auto',

}
export default AboutUs
