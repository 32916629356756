import { Box, Container, useMediaQuery } from '@mui/material'
import React from 'react'
import CardContact from '../Contact/CardContact'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; 
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useTheme } from '@emotion/react';
const GroupContactData = () => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Container maxWidth={'lg'}>
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between', mt: 4,
                    flexDirection: { md: 'row', xs: 'column' }, gap: 4
                }} >
                    <a href="https://whas.me/CRf5647QQj" style={{ textDecoration: 'none', color: '#FFF', width:isMobile ||'45%' }} >
                    <CardContact name={'واتس أب'} desc={'0508001063'} >
                        <WhatsAppIcon sx={{ color: '#74D7E4', fontSize: 45,}} />
                    </CardContact>
                    </a>
                    <a href="tel:+966508001063" style={{ textDecoration: 'none', color: '#FFF', width:isMobile ||'45%' }} >
                        <CardContact name={'رقم التليفون'} desc={'0508001063'} >
                            <PhoneIphoneIcon sx={{ color: '#74D7E4', fontSize: 45,}} />
                        </CardContact>
                    </a>
                </Box>
            </Container>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3703.2433815474174!2d39.10078807527954!3d21.848145980014234!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDUwJzUzLjMiTiAzOcKwMDYnMTIuMSJF!5e0!3m2!1sen!2seg!4v1711403979239!5m2!1sen!2seg" width="100%" height="450" style={{ border:'none', margin: '16px 0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title={'test'}></iframe>
        </>
    )
}

export default GroupContactData